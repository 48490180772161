var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"news"},[_c('section-title',{attrs:{"title":"News list","link":"news-add"}}),(_vm.items && _vm.items.length)?_c('v-card',[_c('v-data-table',{staticClass:"table",attrs:{"headers":_vm.headers,"items":_vm.items,"disable-sort":"","footer-props":{
              'items-per-page-options': [10, 25, 50, 100],
            }},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
            var item = ref.item;
return [_c('img',{attrs:{"src":item.image}})]}},{key:"item.published",fn:function(ref){
            var item = ref.item;
return [(item.published)?_c('v-btn',{staticClass:"white--text",attrs:{"x-small":"","color":"secondary","disabled":""}},[_vm._v(" published ")]):_c('v-btn',{staticClass:"white--text",attrs:{"x-small":"","color":"primary"},on:{"click":function($event){$event.preventDefault();return _vm.publishItem(item.id)}}},[_vm._v(" publish ")])]}},{key:"item.active",fn:function(ref){
            var item = ref.item;
return [_c('v-switch',{attrs:{"dense":""},on:{"change":function($event){return _vm.changeActivity(item)}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('router-link',{attrs:{"to":{
                    name: 'news-edit',
                    params: { id: item.id },
                  }}},[_c('v-btn',{attrs:{"size":"sm","icon":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pen")])],1)],1),_c('v-btn',{attrs:{"size":"sm","icon":""},on:{"click":function($event){return _vm.removeDialog(item.id)}}},[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v(" mdi-delete")])],1)]}}],null,true)})],1):_c('div',[_c('empty-alert')],1),_c('remove-dialog',{on:{"delete":_vm.removeItem},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}}),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.publishDialog),callback:function ($$v) {_vm.publishDialog=$$v},expression:"publishDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 primary white--text",staticStyle:{"color":"white"}},[_vm._v(" Attention ")]),_c('v-card-text',[_c('p',{staticClass:"mt-5 mb-0"},[_vm._v(" This news will be published ")])]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.publishDialog=false}}},[_vm._v(" Cancel")]),_c('v-btn',{staticClass:"white--text",attrs:{"color":"primary"},on:{"click":_vm.approvePublish}},[_vm._v(" OK")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }